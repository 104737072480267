<template>
  <!-- 
    Displays a weather forecast for three days
  -->
  <Portlet
    :title="$t('weather.weather')"
    icon="sun"
    class="weather"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="dailyWeatherAvailable">
        <table
          class="defaultTable only-screen-xl"
        >
          <colgroup>
            <col width="100">
          </colgroup>
          <thead>
            <tr>
              <th class="font-weight-bold">
                {{ $t('weather.icon') }}
              </th>
              <th class="font-weight-bold">
                {{ $t('weather.date') }}
              </th>
              <th class="font-weight-bold">
                {{ $t('weather.temperature') }}
              </th>
              <th class="font-weight-bold">
                {{ $t('weather.windSpeed') }}
              </th>
              <th class="font-weight-bold">
                {{ $t('weather.precepitation') }}
              </th>
              <th class="font-weight-bold">
                {{ $t('weather.summary') }}
              </th>
              <th class="font-weight-bold">
                {{ $t('weather.options') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in slicedWeather"
              :key="trKey(index, 1)"
            >
              <td>
                <skycon :condition="data.icon" />
              </td>
              <td>{{ dayInQuestion(data, index) }}</td>
              <td>
                <span>{{ computedMinTemperature(data) }}</span>
                <br>
                <span>{{ computedMaxTemperature(data) }}</span>
              </td>
              <td>
                <span>{{ computedWindSpeed(data) }} </span>
                <sup>km</sup>
                <span>/</span>
                <sub>h</sub>
                <br>
                <span>{{ computedBeaufortScale(data) }}</span>
              </td>
              <td>{{ computedPrecipitation(data) }}</td>
              <td>{{ data.summary }}</td>
              <td>
                <a
                  :href="computedLink(data)"
                  class="btn btn-sm btn-primary btn-block"
                  target="_blank"
                ><font-awesome-icon
                  class="mr-2"
                  icon="eye"
                  style="vertical-align: middle;"
                />{{ $t('weather.details') }}</a>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          v-for="(data, index) in slicedWeather"
          :key="trKey(index, 2)"
          class="defaultTable not-screen-xl"
        >
          <thead>
            <tr>
              <th>
                <span
                  v-if="equalsZero(index)"
                  class="font-weight-bold"
                >{{ $t('weather.today') }}</span>
                <span
                  v-else-if="equalsOne(index)"
                  class="font-weight-bold"
                >{{ $t('weather.tomorrow') }}</span>
                <span
                  v-else-if="equalsTwo(index)"
                  class="font-weight-bold"
                >{{ $t('weather.dayAfterTomorrow') }}</span>
                <span
                  v-else
                  v-tooltip="`${ data.time | dateTime_dateTime }`"
                  data-toggle="tooltip"
                  data-placement="top"
                  class="font-weight-bold"
                >{{ data.time | dateTime_fromNow }}</span>
              </th>
              <th>
                <skycon :condition="data.icon" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-bold">
                {{ $t('weather.temperature') }}
              </td>
              <td>
                <span>{{ computedMinTemperature(data) }}</span>
                <br>
                <span>{{ computedMaxTemperature(data) }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('weather.windSpeed') }}
              </td>
              <td>
                <span>{{ computedWindSpeed(data) }} </span>
                <sup>km</sup>
                <span>/</span>
                <sub>h</sub>
                <br>
                <span>{{ computedBeaufortScale(data) }}</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('weather.precepitation') }}
              </td>
              <td>{{ (data.precipProbability * 100).toFixed(2) }}{{ $t('weather.changeOf') }} {{ data.precipType }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('weather.summary') }}
              </td>
              <td>{{ data.summary }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('weather.options') }}
              </td>
              <td>
                <a
                  :href="computedLink(data)"
                  class="btn btn-sm btn-primary btn-block"
                  target="_blank"
                >{{ $t('weather.detailedForecast') }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'Weather',
  mixins: [
    dateTimeMixin
  ],
  props: {
    limit: {
      type: Number,
      required: true
    },
    latitude: {
      type: Number,
      required: true
    },
    longitude: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      language: "en",
      units: "ca",
      weatherData: null,
      loading: true
    }
  },
  computed: {
    slicedWeather: function () {
      return this.weatherData.daily.data.slice(0, this.limit);
    },
    dailyWeatherAvailable: function () {
      if (this.weatherData && this.weatherData.daily) return true;
      return false;
    }
  },
  created () {
    this.getWeather();
  },
  methods: {
    computedPrecipitation (data) {
      return `${ (data.precipProbability * 100).toFixed(2) }${ this.$t('weather.changeOf') } ${ data.precipType }`;
    },
    computedLink () {
      return `https://darksky.net/forecast/${ this.latitude },${ this.longitude }/${ this.units }12/${ this.language }`;
    },
    equalsZero (num) {
      return num == 0;
    },
    equalsOne (num) {
      return num == 1;
    },
    equalsTwo (num) {
      return num == 2;
    },
    computedWindSpeed (data) {
      return data.windSpeed ;
    },
    computedBeaufortScale (data) {
      let windSpeedString;
      windSpeedString = data.windSpeed;
      if (windSpeedString < 0.5) {
        windSpeedString = `${ windSpeedString } (${ this.$t('weather.calm') })`;
      } else if (windSpeedString <= 1.5) {
        windSpeedString = `(${ this.$t('weather.lightAir') })`;
      } else if (windSpeedString <= 3.3) {
        windSpeedString = `(${ this.$t('weather.lightBreeze') })`;
      } else if (windSpeedString <= 5.5) {
        windSpeedString = `(${ this.$t('weather.gentleBreeze') })`;
      } else if (windSpeedString <= 7.9) {
        windSpeedString = `(${ this.$t('weather.moderateBreeze') })`;
      } else if (windSpeedString <= 10.7) {
        windSpeedString = `(${ this.$t('weather.freshBreeze') })`;
      } else if (windSpeedString <= 13.8) {
        windSpeedString = `(${ this.$t('weather.strongBreeze') })`;
      } else if (windSpeedString <= 17.1) {
        windSpeedString = `(${ this.$t('weather.moderateGale') })`;
      } else if (windSpeedString <= 20.7) {
        windSpeedString = `(${ this.$t('weather.gale') })`;
      } else if (windSpeedString <= 24.4) {
        windSpeedString = `(${ this.$t('weather.strongGale') })`;
      } else if (windSpeedString <= 28.4) {
        windSpeedString = `(${ this.$t('weather.storm') })`;
      } else if (windSpeedString <= 32.6) {
        windSpeedString = `(${ this.$t('weather.violentStorm') })`;
      } else {
        windSpeedString = `(${ this.$t('weather.hurricane') })`;
      }
      return windSpeedString;
    },
    computedMinTemperature (data) {
      return `min: ${ data.temperatureMin } °C`;
    },
    computedMaxTemperature (data) {
      return `max: ${ data.temperatureMax } °C`;
    },
    trKey (index, number) {
      return `tr${ number }${ index }`;
    },
    dayInQuestion (data, index) {
      switch (index) {
        case 0:
          return this.$t('weather.today');
        case 1:
          return this.$t('weather.tomorrow');
        case 2:
          return this.$t('weather.dayAfterTomorrow');
        default:
          // eslint-disable-next-line
          return data.time | dateTime_fromNow;
      }
    },
    // GET WEATHER (gets weather data from darkSky-api-service)
    getWeather () {
      this.loading = true;
      this.axios.get(`/Weather/Get?latitude=${ this.latitude }&longitude=${ this.longitude }&units=${ this.units }&language=${ this.$i18n.locale }`)
        .then((response) => {
          if (response.data && response.data.response) {
            this.weatherData = response.data.response;
          }
          if (!(this.weatherData && Object.keys(this.weatherData) && Object.keys(this.weatherData).length > 0)) {
            this.$snotify.error("There is no weather-data to be loaded.");
          }
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            window.$('[data-toggle="tooltip"]').tooltip();
          });
        });
    }
  }
}
</script>

<style scoped>
table th {
  vertical-align: middle;
}
table canvas {
  margin: auto;
  display: block;
  height: 50px;
}
</style>
